import { AppPlugin, EventBusSrv, PluginExtensionEventHelpers, type AppRootProps } from '@grafana/data';
import { config, sidecarServiceSingleton_EXPERIMENTAL } from '@grafana/runtime';
import { LoadingPlaceholder } from '@grafana/ui';
import React, { Suspense, lazy } from 'react';
import { Bookmark } from 'utils/types';
import { eventBus, setEventBus } from 'utils/utils.routing';
import pluginJson from './plugin.json';
import { reportAppInteraction, UserInteraction } from 'utils/analytics';

setEventBus(new EventBusSrv());

const LazyApp = lazy(() => import('./components/App/App'));

const App = (props: AppRootProps) => (
  <Suspense fallback={<LoadingPlaceholder text="" />}>
    <LazyApp {...props} />
  </Suspense>
);

export const plugin = new AppPlugin<{}>().setRootPage(App);

const title = 'Open Exploration';
const bookmarkIds: Set<string> = new Set();

if (config.featureToggles.appSidecar) {
  plugin.addLink({
    targets: ['grafana/commandpalette/action'],
    title: 'Open Investigations',
    description: 'Opens the investigations app',
    onClick: () => {
      openApp();
    },
  });

  plugin.addLink({
    title,
    description: title,
    targets: [
      'grafana-lokiexplore-app/metric-exploration/v1',
      'grafana-explore-metrics/exploration/v1',
      'grafana-pyroscope-app/exploration/v1',
    ],
    configure: (context: Bookmark | undefined) => {
      if (!context) {
        return;
      }
      const isAdded = bookmarkIds.has(context.id);
      return {
        icon: 'panel-add',
        category: isAdded ? 'disabled' : 'enabled',
        description: 'Open Exploration',
      };
    },
    onClick: (_, helpers) => {
      if (!helpers.context) {
        return;
      }
      openApp(helpers);

      bookmarkIds.add(helpers.context.id);

      setTimeout(() => {
        //  TODO: for now we will live with the deprecation warning, though we should change
        // eslint-disable-next-line deprecation/deprecation
        eventBus.emit('open-exploration', { context: helpers.context });
      }, 500);
      reportAppInteraction(UserInteraction.ExplorationItemAdded, { from: 'click' });
    },
  });
}

function openApp(helpers?: PluginExtensionEventHelpers) {
  if (!sidecarServiceSingleton_EXPERIMENTAL.isAppOpened(pluginJson.id)) {
    // @ts-ignore
    if (sidecarServiceSingleton_EXPERIMENTAL.openAppV3) {
      // @ts-ignore
      sidecarServiceSingleton_EXPERIMENTAL.openAppV3({ pluginId: pluginJson.id, follow: true });
    } else {
      // TODO: the passed context is not used yet, switch to use `pluginContext`
      sidecarServiceSingleton_EXPERIMENTAL.openApp(pluginJson.id, helpers ? helpers.context : undefined);
    }
  }
}
