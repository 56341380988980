import { EventBusSrv } from '@grafana/data';
import { PLUGIN_BASE_URL } from '../constants';

// Prefixes the route with the base URL of the plugin
export function prefixRoute(route: string): string {
  return `${PLUGIN_BASE_URL}/${route}`;
}

let eventBus = new EventBusSrv();

export function setEventBus(bus: EventBusSrv) {
  eventBus = bus;
}

export { eventBus };
