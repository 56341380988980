import { AppPlugin, EventBusSrv, type AppRootProps } from '@grafana/data';
import { config, sidecarServiceSingleton_EXPERIMENTAL } from '@grafana/runtime';
import { LoadingPlaceholder } from '@grafana/ui';
import React, { Suspense, lazy } from 'react';
import { Context } from 'utils/types';
import { eventBus, setEventBus } from 'utils/utils.routing';
import pluginJson from './plugin.json';
import { reportAppInteraction, UserInteraction } from 'utils/analytics';

setEventBus(new EventBusSrv());

const LazyApp = lazy(() => import('./components/App/App'));

const App = (props: AppRootProps) => (
  <Suspense fallback={<LoadingPlaceholder text="" />}>
    <LazyApp {...props} />
  </Suspense>
);

export const plugin = new AppPlugin<{}>().setRootPage(App);

const title = 'Open Exploration';
const bookmarkIds: Set<string> = new Set();

if (config.featureToggles.appSidecar) {
  plugin.addLink({
    title,
    description: title,
    targets: ['grafana-lokiexplore-app/metric-exploration/v1', 'grafana-explore-metrics/exploration/v1'],
    configure: (context: Context | undefined) => {
      if (!context) {
        return;
      }
      const isAdded = bookmarkIds.has(context.id);
      return {
        icon: 'panel-add',
        category: isAdded ? 'disabled' : 'enabled',
        description: 'Open Exploration',
      };
    },
    onClick: (_, helpers) => {
      if (!helpers.context) {
        return;
      }
      if (!sidecarServiceSingleton_EXPERIMENTAL.isAppOpened(pluginJson.id)) {
        // TODO: the passed context is not used yet, switch to use `pluginContext`
        sidecarServiceSingleton_EXPERIMENTAL.openApp(pluginJson.id, helpers.context);
      }

      bookmarkIds.add(helpers.context.id);

      setTimeout(() => {
        //  TODO: for now we will live with the deprecation warning, though we should change
        // eslint-disable-next-line deprecation/deprecation
        eventBus.emit('open-exploration', { context: helpers.context });
      }, 500);
      reportAppInteraction(UserInteraction.ExplorationItemAdded, { from: 'click' });
    },
  });
}
